import { truncate } from "lodash";
import React from "react";

export default function TextTruncate(props) {
  const { className, children, maxLength } = props;
  const id = children.toString().split(',')[2] != undefined ? '#' + children.toString().split(',')[2] : '';
  const name = truncate(children.toString().split('#')[0], { length: maxLength });
  const title = children.toString().split('#')[0] + id;

  return (
    <div className={`text-break ${className}`} title={title}>
      {name} {id}
    </div>
  );
}
