import React from "react";

export default function DefaultUserIcon({ width = 40, height = 40, fill = "#F2F2F2", ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="20" fill={fill} />
      <path
        d="M8.84284 16.6724L16.4638 12.4083L15.8766 13.8983L10.3504 19.4421C10.3115 19.4815 10.2603 19.5066 10.2053 19.5133C10.1503 19.52 10.0947 19.5079 10.0475 19.4789C10.0002 19.45 9.96417 19.4059 9.94517 19.3538C9.92617 19.3018 9.92534 19.2449 9.94282 19.1923L10.3416 17.9564C10.3605 17.8965 10.3562 17.8316 10.3293 17.7748C10.3025 17.7179 10.2553 17.6732 10.197 17.6497L8.88228 17.115C8.83848 17.099 8.80013 17.0709 8.77173 17.034C8.74333 16.997 8.72605 16.9527 8.72192 16.9062C8.71778 16.8598 8.72695 16.8131 8.74837 16.7717C8.76978 16.7303 8.80255 16.6959 8.84284 16.6724Z"
        fill="#C2002F"
      />
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.35">
        <path
          d="M8.84284 16.6724L16.4638 12.4083L15.8766 13.8983L10.3504 19.4421C10.3115 19.4815 10.2603 19.5066 10.2053 19.5133C10.1503 19.52 10.0947 19.5079 10.0475 19.4789C10.0002 19.45 9.96417 19.4059 9.94517 19.3538C9.92617 19.3018 9.92534 19.2449 9.94282 19.1923L10.3416 17.9564C10.3605 17.8965 10.3562 17.8316 10.3293 17.7748C10.3025 17.7179 10.2553 17.6732 10.197 17.6497L8.88228 17.115C8.83848 17.099 8.80013 17.0709 8.77173 17.034C8.74333 16.997 8.72605 16.9527 8.72192 16.9062C8.71778 16.8598 8.72695 16.8131 8.74837 16.7717C8.76978 16.7303 8.80255 16.6959 8.84284 16.6724Z"
          fill="#2E2E2E"
        />
      </g>
      <path
        d="M20.1144 29C24.9551 29 28.8792 25.0759 28.8792 20.2353C28.8792 15.3946 24.9551 11.4705 20.1144 11.4705C15.2737 11.4705 11.3496 15.3946 11.3496 20.2353C11.3496 25.0759 15.2737 29 20.1144 29Z"
        fill="#2E2E2E"
      />
      <path
        d="M15.0966 13.0526C14.2155 13.6699 13.4537 14.442 12.8484 15.3314C17.654 14.4856 22.5704 14.4856 27.376 15.3314C26.7723 14.4422 26.012 13.67 25.1322 13.0526C21.7976 12.6758 18.4312 12.6758 15.0966 13.0526Z"
        fill="#C2002F"
      />
      <path
        d="M24.8342 21.5499C21.7641 20.5597 18.4603 20.5597 15.3902 21.5499L15.1492 21.6332C14.8862 21.7327 14.6027 21.7655 14.3239 21.7285C14.0452 21.6916 13.78 21.5861 13.552 21.4216C13.3241 21.257 13.1405 21.0385 13.0177 20.7855C12.8949 20.5326 12.8367 20.2532 12.8484 19.9723C12.8414 19.5121 12.9064 19.0537 13.0412 18.6137C13.236 17.9924 13.5842 17.4301 14.0538 16.979C14.5233 16.5278 15.099 16.2023 15.7276 16.0325C18.5914 15.2104 21.6287 15.2104 24.4924 16.0325C25.1232 16.2014 25.701 16.527 26.1722 16.9791C26.6434 17.4311 26.9927 17.9949 27.1876 18.6181C27.3245 19.0577 27.3896 19.5164 27.3804 19.9767C27.3921 20.2576 27.3339 20.537 27.2111 20.7899C27.0883 21.0429 26.9047 21.2614 26.6768 21.426C26.4488 21.5905 26.1836 21.696 25.9049 21.7329C25.6262 21.7699 25.3426 21.7371 25.0797 21.6376L24.8342 21.5499Z"
        fill="#F4E1D1"
      />
      <path
        d="M17.0074 17.4087C17.3585 17.4011 17.7007 17.5193 17.9723 17.7419C18.2439 17.9645 18.427 18.2769 18.4886 18.6226C18.4951 18.5526 18.4951 18.4822 18.4886 18.4122C18.4886 18.0147 18.3307 17.6335 18.0496 17.3524C17.7686 17.0714 17.3873 16.9135 16.9898 16.9135C16.5923 16.9135 16.2111 17.0714 15.93 17.3524C15.649 17.6335 15.491 18.0147 15.491 18.4122C15.4867 18.4823 15.4867 18.5525 15.491 18.6226C15.5543 18.2711 15.7428 17.9545 16.0216 17.7313C16.3003 17.5081 16.6506 17.3935 17.0074 17.4087Z"
        fill="#343434"
      />
      <path
        d="M23.217 17.4087C23.5681 17.4011 23.9103 17.5193 24.1819 17.7419C24.4535 17.9645 24.6367 18.2769 24.6982 18.6226C24.7026 18.5525 24.7026 18.4823 24.6982 18.4122C24.6982 18.0147 24.5403 17.6335 24.2593 17.3524C23.9782 17.0714 23.597 16.9135 23.1995 16.9135C22.802 16.9135 22.4207 17.0714 22.1397 17.3524C21.8586 17.6335 21.7007 18.0147 21.7007 18.4122C21.6942 18.4822 21.6942 18.5526 21.7007 18.6226C21.7631 18.2707 21.9513 17.9535 22.2303 17.7302C22.5093 17.5068 22.86 17.3925 23.217 17.4087Z"
        fill="#343434"
      />
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.35">
        <path
          d="M20.1099 29.0001C21.5434 29.0001 22.955 28.6485 24.221 27.9762C25.4871 27.3038 26.5688 26.3312 27.3716 25.1436H27.3453C25.0445 24.224 22.5876 23.7582 20.1099 23.7719C18.795 23.7687 17.4829 23.8935 16.1921 24.1444C16.0637 24.1707 15.9331 24.184 15.8021 24.1839C14.759 24.1839 14.0097 23.3468 13.8124 22.3213C13.1332 18.8461 15.022 14.6521 16.5383 12.2418C14.7113 13.0635 13.221 14.4862 12.3154 16.2731C11.4098 18.0599 11.1436 20.1031 11.5612 22.0623C11.9788 24.0216 13.0549 25.7786 14.6103 27.041C16.1658 28.3033 18.1067 28.9947 20.1099 29.0001Z"
          fill="#2E2E2E"
        />
      </g>
    </svg>
  );
}
