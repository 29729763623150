import { Form, Input, Checkbox, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login as doLogin, setLoggedIn } from "@/reducers/account";
import { useDispatch, useSelector } from "react-redux";
import loginLogo from "@/asset/img/logo_login.svg";
import njcall from "@/asset/img/nj_call.svg";
import google from "@/asset/img/icons/google-icon.svg";
import { getAuth, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "@/lib/firebase";
import { NotiModal } from "./noti-model";

const Login = ({ ...props }) => {
  const account = useSelector((state) => state.account);
  const [error, setError] = useState(null);
  const [noti, setNoti] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (account.isLoggedIn && localStorage.getItem("access_token")) {
      navigate("/");
    }
  }, []);

  // const handleSubmit = async () => {
  //   try {
  //     setLoading(true);
  //     const { payload } = await dispatch(doLogin(form.getFieldsValue()));
  //     console.log({ payload });
  //     if (payload.error) {
  //       setError(payload.message);
  //     } else {
  //       const { access_token, roles, isFirstLogin } = payload;

  //       const isRider = roles?.some((r) => r.role?.name === "Rider");
  //       localStorage.setItem("isRider", JSON.stringify(isRider));

  //       if (isFirstLogin) {
  //         setShowModalChangePass(true);
  //         setToken(access_token);
  //       } else {
  //         setError(null);

  //         dispatch(setLoggedIn(true));
  //         localStorage.setItem("access_token", access_token);

  //         navigate("/histories");
  //       }
  //     }
  //   } catch (e) {
  //     setError(e);
  //     console.error(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // if (account.isLoggedIn && localStorage.getItem("access_token")) {
  //   return null;
  // }


  const loginWithTokenId = async (tokenId) => {
    const { payload } = await dispatch(doLogin(
      {
        token: tokenId,
        method: 1
      }));

    if (payload.error) {
      if (payload)
        if (payload.code === 404) {
          setNoti(payload.message);
          return;
        }
      setError(payload.message);
    } else {
      const { access_token, roles } = payload;

      const isRider = roles?.some((r) => r.role?.name === "Rider");
      localStorage.setItem("isRider", JSON.stringify(isRider));
      setError(null);

      dispatch(setLoggedIn(true));
      localStorage.setItem("access_token", access_token);
      navigate("/histories");
    }
  }


  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider); // Use the auth instance when calling signInWithPopup
      if (res.user) {
        const { _tokenResponse } = res;
        loginWithTokenId(_tokenResponse?.oauthIdToken);
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };


  return (
    <div className="login-page">
      <div className="pt-6 pl-6">
        <img src={loginLogo} />
      </div>
      <div className="mt-10 flex justify-center  relative z-100">
        <div className="flex flex-col items-center w-[385px]">
          <div className="max-w-[60px]">
            <img className="mb-5" src={njcall} />
          </div>
          <div className="text-primary text-xl font-semibold mb-3">
            Ninja Van Call Management
          </div>
          <div className="mb-10">Vui lòng đăng nhập để truy cập ứng dụng</div>
          {/* <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="w-full form-label-medium">
            <Form.Item
              name="username"
              labelCol={24}
              label="Email hoặc số điện thoại"
              className="w-full"
              rules={[validationRules.required]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              labelCol={24}
              label="Mật khẩu"
              rules={[validationRules.required]}>
              <Input.Password />
            </Form.Item>
            {error && <p className="text-danger">{error}</p>}
            <div className="flex justify-between items-center">
              <Form.Item name="saveAccount">
                <Checkbox>Ghi nhớ tài khoản</Checkbox>
              </Form.Item>
              <div className="mb-[12px] cursor-pointer" onClick={() => setShowModal(true)}>
                Quên mật khẩu?
              </div>
            </div>
            <Button
              style={{ color: "white" }}
              htmlType="submit"
              type="primary"
              className="bg-primary w-full border-none font-semibold"
              loading={isLoading}>
              Đăng nhập
            </Button>
          </Form> */}
          {error && <p className="text-danger">{error}</p>}
          <Button
            style={{ color: "#3F4254" }}
            onClick={signInWithGoogle}
            type="default"
            className="bg-white w-full border-none font-semibold mt-6 items-center flex justify-center gap-2 hover:bg-gray-100"
          >
            <img src={google} />
            <span >
              Đăng nhập bằng tài khoản Google
            </span>
          </Button>
          {/* <ResetPassModal open={showModal} setOpen={setShowModal} />
          {showModalChangePass && (
            <ChangePassModal
              open={showModalChangePass}
              setOpen={setShowModalChangePass}
              token={token}
            />
          )} */}
        </div>
      </div>
      <div className="login-footer">
        <div>©2022 Nin Sing Logistics Viet Nam. All rights reserved.</div>
      </div>
      <NotiModal noti={noti} onClose={() => setNoti(null)} />
    </div>
  );
};

export default Login;
