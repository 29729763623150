import { lazy } from "react";

import { useRoutes } from "react-router-dom";
import AuthLayout from "@/layout/auth";
import Layout from "@/layout";
import { ScheduleOutlined, AreaChartOutlined, SafetyCertificateOutlined } from "@ant-design/icons";

import LoginPage from "./pages/account/login";

const EmployeesPage = lazy(() => import("./pages/employees"));
const DepartmentsPage = lazy(() => import("./pages/departments"));
const HistoriesPage = lazy(() => import("./pages/histories"));
const HubsPage = lazy(() => import("./pages/hubs"));
const SettingsPage = lazy(() => import("./pages/settings"));
const ProfilePage = lazy(() => import("./pages/settings/profile"));
const PermissionPage = lazy(() => import("./pages/settings/permission"));
const DriverReports = lazy(() => import("./pages/settings/driver-reports"));

import EmployeeIcon from "@/components/icons/employee-icon";
import DepartmentIcon from "@/components/icons/department-icon";
import HistoryIcon from "@/components/icons/history-icon";
import HubIcon from "@/components/icons/hub-icon";
import SettingIcon from "@/components/icons/setting-icon";
import Error404 from "@/pages/errors/404";
import NestedTableExample from "./pages/user-version";
import Checkin from "./pages/checkin";
import { ConfigVoip } from "./pages/config";

export const routes = [
  {
    element: <AuthLayout />,
    path: "/auth",
    children: [
      {
        hidden: true,
        path: "login",
        element: <LoginPage />,
      },
    ],
  },
  {
    element: <Layout />,
    children: [
      {
        icon: <HistoryIcon />,
        caps: "Record.Manage|Record.ManageAll|Record.SelfManage",
        path: "/histories",
        title: "Lịch sử cuộc gọi",
        element: <HistoriesPage />,
      },
      {
        icon: <HistoryIcon />,
        caps: "Record.Manage|Record.ManageAll|Record.SelfManage",
        path: "/",
        element: <HistoriesPage />,
        hidden: true,
      },
      {
        icon: <EmployeeIcon />,
        caps: "User.Manage|User.ManageAll",
        path: "/users",
        title: "Nhân viên",
        element: <EmployeesPage />,
      },
      {
        icon: <DepartmentIcon />,
        caps: "Department.Manage|Department.ManageAll",
        path: "/departments",
        title: "Phòng ban",
        element: <DepartmentsPage />,
      },
      {
        icon: <HistoryIcon />,
        caps: "CallLog.Manage|CallLog.ManageAll",
        path: "/histories/:id",
        title: "Lịch sử cuộc gọi",
        element: <HistoriesPage />,
        hidden: true,
      },
      {
        caps: "Hub.Manage|Hub.ManageAll",
        icon: <HubIcon />,
        path: "/hubs",
        title: "Hub",
        element: <HubsPage />,
      },
      {
        icon: <SettingIcon />,
        path: "/settings",
        title: "Cài đặt",
        element: <SettingsPage />,
        children: [
          {
            path: "profile",
            element: <ProfilePage />,
            hidden: true,
          },
          {
            path: "permission",
            element: <PermissionPage />,
            hidden: true,
          },
          {
            path: "driver-reports",
            element: <DriverReports />,
            hidden: true,
          },
        ],
      },
      {
        caps: "User.Create",
        icon: <ScheduleOutlined style={{ fontSize: "20px" }} />,
        path: "/checkin-import",
        title: "Điểm danh",
        element: <Checkin />,
      },
      // {
      //   icon: <AreaChartOutlined style={{ fontSize: "20px" }} />,
      //   title: "Phân tích dữ liệu",
      //   path: "/data-analysis",
      //   element: <Navigate to="/data-analysis" />,
      // },
      // {
      //   icon: <SafetyCertificateOutlined style={{ fontSize: "20px" }} />,
      //   title: "Quản lý chất lượng",
      //   path: "/quality-management",
      //   element: <Navigate to="/quality-management" />,
      // },
      {
        element: <ConfigVoip />,
        path: "/config/voip",
        hidden: true,
      },
      {
        hidden: true,
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  {
    element: <NestedTableExample />,
    path: "/report/user-version",
  },
];

export default function Routes() {
  const elements = useRoutes(routes);
  return elements;
}